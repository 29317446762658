@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

@font-face {
  font-family: 'Aldrich';
  src: local('Aldrich'), url('./assets/fonts/Aldrich-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'GESndBook';
  src: local('GESndBook'), url('./assets/fonts/GESndBook.ttf') format('truetype');
  font-weight: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #cc9767; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Poppins', sans-serif; */
  height: 100vh;
  font-size: 12px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.changeButton {
  position: relative;
  display: block;
  user-select: none;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}


.modal-container {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.modal-back {
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.message-left {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 2;
  box-sizing: border-box;
  border-radius: 1rem 1rem 1rem 0rem;
  padding: 5px 10px;
  margin: 10px 10px;
  width: 80%;
  align-self: flex-start;

  background: #ddd;
}

.message-right {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 2;
  box-sizing: border-box;
  border-radius: 1rem 1rem 0rem 1rem;
  padding: 5px 10px;
  margin: 10px 10px;
  width: 80%;
  align-self: flex-end;

  background: #cc9767;
}

.tracking-container {
  /* border-width: 1px; */
  /* border-color: #000; */
  /* border-style: solid; */
  height: calc(100vh - 120px + 2px);
  /* border-radius: 4px; */
  overflow: hidden;
  flex-direction: row;
  display: flex;
  padding-top: 15px;
}

.mapctr {
  height: calc(100vh - 120px);
  width: calc(100vw - 500px);
}
.mapcontainer {
  height: calc(100vh - 120px) !important;
  width: calc(100vw - 500px) !important;
}

.fullscreenMap {
  height: 100vh;
  width: 100vw;
}

.tracking-header-container {
  display: flex;
  flex-direction: row;
}

.Dropdown-control {
  border: 0;
  background-color: transparent;
}

.container-row-column {
  flex-direction: column;
  display: flex;
}

@media (max-width: 900px) {
  .mapctr {
    height: calc(100vh - 120px);
    width: calc(100vw - 500px + 240px);
  }
  .mapcontainer {
    height: calc(100vh - 120px) !important;
    width: calc(100vw - 500px + 240px) !important;
  }
}
@media (max-width: 600px) {
  .tracking-container {
    flex-direction: column;
  }
  .mapctr {
    height: calc(100vh - 120px);
    width: calc(100vw - 22px);
  }
  .mapcontainer {
    height: calc(100vh - 120px) !important;
    width: calc(100vw - 22px) !important;
  }
}

.fullscreenButton {
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  overflow: hidden;
  right: 0px;
  z-index: 1000;
}


.content {
  flex-grow: 1;
  padding: 0px 0px;
}
@media (min-width: 900px) {
  /* .content {
    margin-left: 240px;
  } */
}

.appBar {
  margin-left: 300px;
}

.drawerPaper {
  width: 300px;
  overflow-x: hidden;
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiSelect-icon {
  color: rgba(255, 255, 255, 0.54) !important;
}
.home-container {
  background-color: #cc9767;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100vh;
}

.button {
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #ffffff;
  color: #000 !important;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  padding: 16px 32px;
  text-align: center;
  white-space: nowrap;
}
.button:hover {
  background: #262a33;
  color: #fff !important;
}
.button:active {
  outline: 0;
}
.button::before {
  border-radius: 2px;
}
.image {
  width: 40%;
}
@media (max-width: 600px) {
  .image {
    width: calc(100% - 200px) !important;
  }
}
@media (max-width: 450px) {
  .image {
    width: calc(100% - 100px) !important;
  }
}
@media (max-width: 350px) {
  .image {
    width: calc(100% - 50px) !important;
  }
}

.home-page-login {
  position: absolute;
  top: 20px;
  right: 20px;
}

.login-container {
  background-color: #cc9767;
  height: 100vh;
}

a {
  color: #cc9767;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #cc9767;
}

/* FORM TYPOGRAPHY*/

input[type='button'],
input[type='submit'],
input[type='reset'] {
  background-color: #cc9767;
  border: none;
  color: black;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(82, 180, 93, 0.4);
  box-shadow: 0 10px 30px 0 rgba(82, 180, 93, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type='button']:hover,
input[type='submit']:hover,
input[type='reset']:hover {
  background-color: #cc9767;
}

input[type='button']:active,
input[type='submit']:active,
input[type='reset']:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.loginText,
.dropdown,
input[type='password'] {
  background-color: #f6f6f6;
  border: none !important;
  color: #0d0d0d !important;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6 !important;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.loginText:focus,
.dropdown:focus,
input[type='password']:focus {
  background-color: #fff;
  border-bottom: 2px solid #cc9767;
}

.loginText:placeholder,
.dropdown:placeholder,
input[type='password']:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #cc9767;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 20%;
}

* {
  box-sizing: border-box;
}

.error {
  font-size: 10px;
  color: red;
  padding: 10px 0px;
}
